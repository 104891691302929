<script setup lang="ts">
import DeleteShift from '@app/components/shifts/shifts/actions/DeleteShift.vue'
import PlanShift from '@app/components/shifts/shifts/actions/PlanShift.vue'
import SetShiftMembers from '@app/components/shifts/shifts/actions/SetShiftMembers.vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import type { Shift } from '@app/types/shifts'
import { UserIcon } from '@heroicons/vue/20/solid'
import { type PropType } from 'vue'
import ShiftDetails from './ShiftDetails.vue'
import ShiftMembers from './ShiftMembers.vue'

defineProps({
    shift: {
        type: Object as PropType<Shift>,
        required: true,
    },
})

const emit = defineEmits(['close', 'reload'])
</script>

<template>
    <DetailCard :model="shift" :dismissable="true" @close="emit('close')" class="max-w-[26rem]">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle> Shift ID: {{ shift.id }}</DetailCardTitle>
            <div class="flex flex-col items-center gap-4">
                <DetailCardLabel :label="'Campaign' + ' :'">
                    <span>Project name</span>
                </DetailCardLabel>
                <DetailCardLabel :label="'Date' + ' :'">
                    <span>18/09/2024</span>
                </DetailCardLabel>
                <DetailCardLabel :label="'Status' + ' :'">
                    <Badge size="lg" class="leading-6" label="Status" color="gray" />
                </DetailCardLabel>
                <DetailCardLabel :label="'Team' + ' :'">
                    <span>4/4</span>
                </DetailCardLabel>
            </div>
        </div>
        <template #actions>
            <PlanShift v-show="!shift.shiftMembers || shift.shiftMembers.length === 0" :shift="shift" />
            <SetShiftMembers :shift="shift" />
            <DeleteShift :shift="shift" />
        </template>
        <template #tabs>
            <DetailCardTab tab="team" label="Team">
                <template #icon>
                    <UserIcon />
                </template>
                <ShiftMembers :shiftMembers="shift.shiftMembers" />
            </DetailCardTab>
            <DetailCardTab tab="activity" label="Activity">
                <template #icon>
                    <UserIcon />
                </template>
                <ShiftDetails :shift="shift" />
            </DetailCardTab>
            <DetailCardTab tab="details" label="Details">
                <template #icon>
                    <UserIcon />
                </template>
                <ShiftDetails :shift="shift" />
            </DetailCardTab>
        </template>
    </DetailCard>
</template>
