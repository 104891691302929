<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import { useUserCan } from '@app/composables/useUserCan'
import type { Application, ClientProject, LocationType, WorkType } from '@app/types/shared'
import type { ShiftExternalProject } from '@app/types/shifts'
import { getTimeFromTimeObject, timeStringToModel } from '@app/utils/date'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { router, useForm, usePage } from '@inertiajs/vue3'
import type { TimeModel } from '@vuepic/vue-datepicker'
import VueDatePicker from '@vuepic/vue-datepicker'
import { trans } from 'laravel-vue-i18n'
import { computed, ref, toRefs, watch } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{
    project: ShiftExternalProject | null
}>()

const emit = defineEmits(['submit'])

const { project } = toRefs(props)

const { can } = useUserCan()

const applications = usePage().props.applications as Application[]
const workTypes = usePage().props.workTypes as WorkType[]
const clientProjects = usePage().props.clientProjects as ClientProject[]
const locationTypes = usePage().props.locationTypes as LocationType[]

const selectedApplication = useModelSelect(applications, project.value?.application_id)
const selectedWorkType = useModelSelect(workTypes, project.value?.work_type_id)
const selectedClientProject = useModelSelect(clientProjects, project.value?.client_project_id)
const selectedLocationType = useModelSelect(locationTypes, project.value?.location_type_id)

const defaultStartTime = '14:00'
const defaultEndTime = '21:00'

const startTime = ref<TimeModel | null>(timeStringToModel(project.value?.start_time || defaultStartTime))
const endTime = ref<TimeModel | null>(timeStringToModel(project.value?.end_time || defaultEndTime))

const form = useForm({
    name: project.value?.name || '',
    application_id: project.value?.application_id,
    work_type_id: project.value?.work_type_id,
    location_type_id: project.value?.location_type_id,
    client_project_id: project.value?.client_project_id,
    start_time: project.value?.start_time || defaultStartTime,
    end_time: project.value?.end_time || defaultEndTime,
})

watch(startTime, () => {
    if (startTime.value) {
        form.start_time = getTimeFromTimeObject(startTime.value, true)
    } else {
        form.start_time = null
    }
})

watch(endTime, () => {
    if (endTime.value) {
        form.end_time = getTimeFromTimeObject(endTime.value, true)
    } else {
        form.end_time = null
    }
})

function submit() {
    if (project.value) {
        form.put(route('settings.shifts.external-project.update', project.value.id), { onSuccess: () => emit('submit') })
    } else {
        form.post(route('settings.shifts.external-project.store'), { onSuccess: () => emit('submit') })
    }
}

const formTitle = computed(() => (project.value ? project.value.name : trans('actions.create')))

function deleteProject(id: number) {
    router.delete(route('settings.shifts.external-project.destroy', id))
}
</script>

<template>
    <Form :form="form" class="w-full" @submit="submit">
        <FormSection :title="formTitle">
            <FormField prop="name" class="col-span-12" :label="$t('attributes.name')">
                <Input id="name" v-model="form.name" />
            </FormField>

            <FormField prop="application_id" class="col-span-12 sm:col-span-6" :label="$t('attributes.application')">
                <Dropdown
                    :label="$t('attributes.application')"
                    :items="applications"
                    propertyName="name"
                    v-model="selectedApplication"
                    v-model:form="form.application_id"
                />
            </FormField>

            <FormField prop="work_type_id" class="col-span-12 sm:col-span-6" :label="$t('attributes.work_type')">
                <Dropdown
                    :label="$t('attributes.work_type')"
                    :items="workTypes"
                    propertyName="name"
                    v-model="selectedWorkType"
                    v-model:form="form.work_type_id"
                />
            </FormField>

            <FormField prop="client_project_id" class="col-span-12 sm:col-span-6" :label="$t('attributes.client_project')">
                <Dropdown
                    :label="$t('attributes.client_project')"
                    :items="clientProjects"
                    propertyName="name"
                    v-model="selectedClientProject"
                    v-model:form="form.client_project_id"
                />
            </FormField>

            <FormField prop="location_type_id" class="col-span-12 sm:col-span-6" :label="$t('attributes.location_type')">
                <Dropdown
                    :label="$t('attributes.location_type')"
                    :items="locationTypes"
                    propertyName="name"
                    v-model="selectedLocationType"
                    v-model:form="form.location_type_id"
                />
            </FormField>

            <FormField prop="start_time" class="col-span-12 sm:col-span-6" :label="$t('attributes.start')">
                <VueDatePicker
                    v-model="startTime"
                    time-picker
                    text-input
                    :minutes-increment="15"
                    :placeholder="$t('recruitment.leads.outcomes.call_appointments.time')"
                    :config="{ modeHeight: 120 }"
                />
            </FormField>

            <FormField prop="end_time" class="col-span-12 sm:col-span-6" :label="$t('attributes.end')">
                <VueDatePicker
                    v-model="endTime"
                    time-picker
                    text-input
                    :minutes-increment="15"
                    :placeholder="$t('recruitment.leads.outcomes.call_appointments.time')"
                    :config="{ modeHeight: 120 }"
                />
            </FormField>
        </FormSection>

        <FormActions>
            <Button type="button" v-if="project && can('settings.shifts.external-project.destroy')" color="white" @click="deleteProject(project.id)">
                <TrashIcon class="size-4"></TrashIcon> {{ $t('actions.delete') }}
            </Button>
            <SaveButton :mode="project ? 'save' : 'create'" />
        </FormActions>
    </Form>
</template>
