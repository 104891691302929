<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { Shift } from '@app/types/shifts'
import { CalendarIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'

const props = defineProps<{ shift: Shift }>()

const { can } = useUserCan()

const show = computed(() => can('shifts.shift.destroy'))

const planShift = () => {
    console.log('plan shift')
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('actions.plan')" @click="planShift">
        <CalendarIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
